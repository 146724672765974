<template>
  <v-snackbar
    v-model="snackbar"
    elevation="0"
    class="mt-n14 mt-md-n16"
    :color="snack.color"
    :timeout="snack.timeout || 3000"
    multi-line
    app
    top
    right
  >
    {{ snack.message }}
    <template v-slot:action>
      <v-btn icon @click="snackbar = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "SnackbarComponent",

  data: () => ({
    unwatchSnack: null,
    snackbar: false,
    snack: {}
  }),

  created() {
    this._unwatchSnack = this.$store.watch(
      state => state.app.snack,
      snack => {
        if (snack) {
          this.snackbar = true;
          this.snack = snack;

          this.$store.commit("app/snack", null);
        }
      }
    );
  },

  beforeDestroy() {
    this._unwatchSnack();
  }
};
</script>
